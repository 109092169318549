//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// eslint-disable-next-line camelcase
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

import { delDocumentBill, documentBillPage } from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'
import { stateColor } from '@/mixins/index.js'
import { handleAppointEleHeight } from '@/utils/common.js'
import { getDefaultColumns } from './config'
import stepsForm from './stepsForm.vue'
export default {
    name: 'list',
    components: {
        STable,
        OpenData,
        stepsForm,
    },
    mixins: [stateColor],
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            btna: ['全部', '未完成', '已完成'],
            btck: 0,
            // 查詢參數
            //queryParam: { approvalStatus: 0, documentType: 1 },
            queryParam: { documentType: 1, approvalTab: 0 },
            // 表頭
            columns: [],
            // 加載數據方法 必須為 Promise 對象
            loadData: (parameter) => {
                const { approvalStatus } = parameter
                let params = { ...parameter }
                if (approvalStatus && approvalStatus.length > 0)
                    params = { ...parameter, approvalStatus: approvalStatus[0] }
                return documentBillPage(Object.assign(params, this.queryParam)).then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        ele.sendIds = formatDraftId(ele.sendIds)
                        return ele
                    })
                    handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool'], 66, res)
                    return data
                })
            },
            selectedRowKeys: [],
            selectedRows: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
        }
    },
    computed: {},
    beforeDestroy() {
        this.$store.commit('bill/setRegistration', false)
    },
    created() {
        this.getDict()
        init_wx()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool'], 66)
    },
    methods: {
        handleOk() {},
        refresh() {
            this.$refs.table.refresh()
        },
        Filter(arr, s) {
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        /**
         * 獲取字典數據
         */
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
            sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
                this.flow_approval_status = res.data
                if (res.data) this.columns = getDefaultColumns(res.data)
            })
        },
        todoApproval() {
            console.log(this.selectedRowKeys)
        },
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        change_status(i) {
            this.btck = i
            this.columns = getDefaultColumns(this.flow_approval_status, i === 0)
            this.queryParam.approvalTab = i
            this.$refs.table.refresh(true)
        },
        singleDelete({ id }) {
            delDocumentBill([
                {
                    id,
                },
            ])
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('刪除成功')
                        this.refresh()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        edit(record) {
            this.$refs.stepsForm.open({ type: 'edit', id: record.id })
            // this.$router.push({ path: '/fileStreamDocumentManage', query: { id: record.id } })
        },
        // 續審
        renewal(record) {
            this.$refs.stepsForm.open({ type: 'renewal', id: record.id })
        },
        // 重審
        retrial(record) {
            this.$refs.stepsForm.open({ type: 'retrial', id: record.id })
        },
        addApproval(record) {
            this.$refs.stepsForm.open({ type: 'approval', id: record.id })
        },
        senpi(record) {
            this.$router.push({ path: '/fileStream_Approval_Manage', query: { id: record.id } })
        },
        deal(record) {
            this.$router.push({ path: '/fileStream_Approval_process', query: { id: record.id, todo: 'dealWith' } })
        },
        see(record) {
            // this.$refs.stepsForm.open({ type: 'edit', id: record.id })
            this.$router.push({ path: '/fileStream_Approval_process', query: { id: record.id } })
        },
    },
}
